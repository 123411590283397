
@use "sass:color";
@import "@/../sass/_variables.scss";

.qa-banner {
    border: 1px solid $grey3;
    box-sizing: content-box;
    display: inline-block;
    transition:
        border-color 0.2s linear,
        box-shadow 0.2s linear;

    .ivu-poptip-body {
        padding: $spacing;
    }

    .ivu-poptip-popper {
        margin: 2px 0 0 -11px;
    }
}

.qa-banner--selected {
    border-color: $darkgrey1;
    box-shadow: 3px 5px 8px 3px color.adjust($darkgrey1, $lightness: 15%);

    .qa-banner__select-layer {
        cursor: default;
    }
}

.qa-banner__approval-buttons-tooltip {
    height: 40px;
}

.qa-banner__approval-buttons-container {
    display: flex;
}

.qa-banner__footer {
    align-items: center;
    background: $white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.qa-banner__footer-items {
    display: flex;
}

.qa-banner__add-annotation-layer {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &:hover .qa-banner-cursor-info {
        display: block;
    }
}

.qa-banner__select-layer {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.qa-banner__banner-container {
    position: relative;
}

.qa-banner__title {
    color: $darktheme20;
    flex: 1 0;
    font-size: $font-size-small;
    min-width: 0;
}
