
@import "@/../sass/_variables.scss";
code {
    background-color: $grey1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}
ol,
ul {
    padding-left: 20px;
}

.container {
    height: 400px;
    overflow: auto;
    padding-right: 40px;
}
