@import "fonts";
@import "variables";

.hox-full-height {
    height: 100%;
}

.hox-full-width {
    width: 100%;
}

.empty-content-prompt {
    position: fixed;
    font-size: 30px;
    z-index: 10;
    top: 50%;
    margin-top: -30px;
    width: 100%;
    text-align: center;
    left: 0;
    color: #ccc;

    .maxi-button {
        .ivu-icon {
            font-size: 70px;
        }
    }
}

.empty-content-prompt .icon {
    font-size: 100px;
    opacity: 0.3;
}

.loader {
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    margin-top: -30px;
    margin-left: -30px;
}

.hoxton-header {
    padding: 0;
    line-height: 0;
    height: 60px;
    font-size: 11px;
    text-transform: uppercase;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #515a6e;
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

body,
html {
    margin: 0;
    padding: 0;
}

.main-content,
.sidebarWrapper,
.sidebarWrapper {
    background-color: #ffffff;
}

.sidebarWrapper {
    display: flex;
    min-height: 100vh;
    align-items: flex-start;
    border-width: 3px 0 3px 3px;
    border-style: solid;
    -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#ffffff), to(#e9e9e9)) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(right, #ffffff, #e9e9e9) 1 100%;
    -moz-border-image: -moz-linear-gradient(right, #ffffff, #e9e9e9) 1 100%;
    -o-border-image: -o-linear-gradient(right, #ffffff, #e9e9e9) 1 100%;
    border-image: linear-gradient(to right, #ffffff, #e9e9e9) 1 100%;

    &.ivu-layout-sider-collapsed {
        border: 0 none;
    }
}

.LoadingBar {
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    position: fixed;
    top: 0;
    background: #48d9ff;
    height: 3px;
    opacity: 1;
    z-index: 11;
}

.LoadingBar-glow {
    top: 0;
    position: absolute;
    width: 100px;
    height: 100%;
    box-shadow: -3px 0 15px 1px rgba(0, 255, 231, 0.4);
}

.LoadingBar--to_right {
    left: 0;
}

.LoadingBar--to_left,
.LoadingBar--to_right .LoadingBar-glow {
    right: 0;
}

.LoadingBar--to_left .LoadingBar-glow {
    left: 0;
}

.LoadingBar--full {
    transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    height: 0;
    opacity: 0;
}

.LoadingBar--error {
    background: #f44336;
}

.LoadingBar--error .LoadingBar-glow {
    box-shadow: -3px 0 15px 1px rgba(244, 67, 54, 0.4);
}

.loading-bar {
    position: relative;
    z-index: 9999999;
}

.paginatedList {
    margin-bottom: 25px;
}

.paginatedList .pagination {
    margin-top: 25px;
}

.maxi-button {
    border-radius: 10px;
    margin-bottom: 15px;
    color: #ccc;
    font-size: 50px;
    font-weight: 100;
    height: 100px;
    width: 100px;

    &:hover {
        color: #0f8ee9;
    }
}

.pull-right {
    float: right;
}

.capitalize-text {
    text-transform: capitalize;
}

.paging-controls {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .paging-control {
        flex: 0;

        &-pages {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////
///////// VUEBAR style
///////////////////////////////////////////////////////////////////////////////////////
.vb {
    &-dragger {
        backface-visibility: hidden;
        background: $vb-dragger-color;
        border-radius: calc($vb-width / 2);
        right: 3px;
        transform: rotate3d(0, 0, 0, 0);
        transition: background 0.2s ease-in-out;
        width: $vb-width;
        z-index: 8;

        &:hover {
            background: $vb-dragger-hover-color;
        }
    }

    &-scrolling-phantom .vb-dragger,
    &-dragging .vb-dragger,
    &-dragging-phantom .vb-dragger {
        background: $vb-dragger-color;
    }
}

//////////////////////////////////////////
//// Campaign shared styles

@import "./campaigns.base";

.data-sort {
    &__direction {
        float: right;
        font-size: 12px;
        color: $grey5;
        line-height: 17px;
    }

    .ivu-form-item-label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 44px;
    }

    .ivu-select-dropdown {
        width: 200px;
    }

    .ivu-select-group-title {
        display: none;
    }

    .ivu-select-group-wrap {
        border-bottom: 1px solid $grey2;
        padding: 4px 0;

        &:last-child {
            border-bottom: 1px solid transparent;
            padding-bottom: 0;
        }
    }
}

.link-like {
    color: $blue;
    font-weight: normal;
    white-space: nowrap;
    cursor: pointer;
}

.white-space-nowrap {
    white-space: nowrap;
}

.hidden {
    display: none;
}

.remember-checkbox {
    flex: 1;
}

@media all and (-ms-high-contrast: none) {
    /*fixes some styles in IE*/
    html,
    body,
    .app-wrapper,
    .theme,
    .campaign-layout,
    .main-content,
    .campaign-content {
        height: 100%;
    }

    #app .clients,
    #app .campaigns {
        min-height: 100vh;
    }

    #app .clients__action-item,
    #app .campaigns__action-item,
    #app .deliverable-drawer-nav {
        flex: 0 0 auto;
    }

    .app-wrapper .hox-modal {
        margin: 0;
        align-self: center;

        .alert__icon-container {
            flex-basis: 64px;
        }
    }
}

.hox-drawer {
    .ivu-drawer-body {
        flex: 1;
        height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .ivu-drawer-close {
        color: $grey5;
        cursor: pointer;
        position: absolute;
        transition: color 0.2s ease-in-out;
        z-index: 9;

        &:hover {
            color: $darkgrey2;
        }
    }

    .ivu-drawer-content {
        display: flex;
        flex-direction: column;
    }

    .ivu-drawer-header {
        border-bottom: 1px solid $grey2;
        padding: $spacing $spacing-large;
    }

    &-header {
        display: flex;
        width: 100%;

        &-info {
            display: flex;
            flex: 1 0;
            margin-top: 6px;
            margin-bottom: 6px;
            margin-right: 30px;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            &__label {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                &-text {
                    min-width: 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .qa-status-label {
                margin-top: 4px;
            }
        }
    }

    &__header {
        background-color: $white;
        flex: 0 0 auto;
        padding: 0 $spacing-large;
        border-bottom: 1px solid $grey2;
    }

    &-nav {
        flex: 0 0;
        margin-right: 20px;
        margin-top: 12px;
        white-space: nowrap;

        &--bulk {
            flex: 0 0 150px;
        }

        > .deliverable-drawer-nav-item.ivu-btn {
            float: none;
        }
    }

    &-nav-item {
        white-space: nowrap;
    }

    &--hide-header {
        .ivu-drawer-header {
            display: none;
        }
    }

    &__body {
        background: $cmp-light-bg-color;
        flex: 1;
        height: auto;
        position: relative;
        overflow: auto;
        display: flex;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: row;
        overflow: hidden;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1 1 auto;
    }

    &__container {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateZ(0);

        &-header {
            background-color: $white;
            flex: 0 0;
            padding: 0 $spacing-large;
            border-bottom: 1px solid $grey2;
            justify-content: flex-end;
        }
    }

    &__error-message-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: $spacing $spacing-large 0;
        width: 100%;
    }

    &__loading-spinner-wrapper {
        color: $cmp-light-font-color;
        font-size: 60px;
        line-height: 0;
    }

    &__footer {
        align-items: center;
        border-top: 1px solid $grey3;
        display: flex;
        padding: $spacing-small $spacing-large;
    }
}

.kpi-performance__results {
    padding-left: $spacing-semi-small;
    display: flex;

    &-kpi {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: $font-size-smaller;
        flex: 0 0 50px;
        text-align: center;
        width: 50px;
        border-left: 1px solid $cmp-light-border-color;
        padding: $spacing-smallest;

        &-perf {
            display: flex;
            justify-content: space-between;
            align-content: center;
            font-weight: bold;
            font-size: 10px;
        }

        &-name {
            text-align: center;
            align-self: center;
            color: $cmp-light-font-color;
            width: 100%;
            height: 16px;
        }
    }
}
