
@use "sass:color";
@import "@/../sass/_variables.scss";
.creative-insights-chart {
    &__popper-tooltip {
        position: relative;
        z-index: 100;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-between;
        width: 20vh;
        max-width: 300px;
        background: #fff;

        &--hidden {
            position: absolute;
            left: -10000px !important;
            top: auto;
            overflow: hidden;
        }

        &-list {
            font-size: 11px;
            background-color: color.adjust($darktheme40, $alpha: -0.1);
            border-radius: $border-radius-base;
            color: $white;
            padding: $spacing-smaller $spacing-smaller;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            list-style: none;

            dl {
                padding: 0;
            }

            dt {
                margin-left: 0;
            }

            dd {
                margin-left: $spacing-semi-small;
            }
        }

        &-img {
            width: 100%;
            height: 100%;
            max-height: 250px;
            object-fit: contain;
            background-color: $white;
        }

        &-img-loader {
            padding: $spacing-small;
            margin: auto;
            height: 250px;
        }

        &-img-placeholder,
        &-img-loader {
            width: 20vh;
            max-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 4px;
            max-height: 250px;
            background-color: $white;
            margin-top: -5px;
        }
    }

    &__tooltip {
        font-size: 12px;
        z-index: 20;
        width: 1px;
        height: 0;
        background-color: red;
        border-radius: 50%;
        pointer-events: none;
    }

    &__tooltip-entry-content {
        cursor: pointer;
    }

    &__popper-tooltip-img {
        display: none;
        opacity: 0;
        &.active {
            display: inline-block;
            transition: opacity 0.6s linear;
            opacity: 1;
        }
    }
    &__popper-tooltip-animation {
        height: 5px;
        width: 0%;
        background: $blue;
        position: relative;
        top: 0;
        left: 0;
        z-index: 100;
        &.animate {
            width: 0%;
            animation: load 1.5s normal forwards;
        }
    }
}
@keyframes load {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
