
@import "@/../sass/_variables.scss";

.importExportModal {
    &__upload-controls-label {
        margin-right: $spacing-small;
    }

    &__navigation {
        margin-bottom: $spacing;
        border-bottom: 2px solid #e7eaee;
        li {
            margin-bottom: -2px;
        }
    }

    &__howto {
        &-list {
            padding-left: $spacing;
        }
    }

    &__files {
        padding-left: $spacing;
        margin-bottom: $spacing;
    }

    &__upload-result {
        margin-top: $spacing;
    }

    &__upload-results-error-list {
        padding-left: $spacing;
        word-break: break-word;
    }

    &__upload-controls {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 10px;

        .labelled-switch {
            flex: 0;

            &__label {
                white-space: nowrap;
            }
        }
        .editable-group-batch-upload {
            width: 100%;
            .ivu-upload {
                display: block;
                height: 150px;
            }
            &__button {
                height: 100%;
            }
            &__description {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px 0;
                background: #f4f5f7;
                padding: 5px 10px;
                .ivu-icon {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
            .editable-group-batch-upload__button {
                button.ivu-btn {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    border: 1px dashed #333;
                    width: 100%;
                    height: 100%;
                    color: #333;
                    font-weight: normal;
                    span {
                        &.batch-upload--link {
                            color: #0014cc;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    &--update {
        opacity: 0;
        top: 100%;
    }
    &--upload {
        .hox-modal {
            width: 840px;
        }
        .alert {
            border-radius: 8px;
            background-color: #e7eaee;
            padding: 16px;
            &.importExportModal__upload-result {
                margin-top: 0;
                padding-top: 0;
                padding-left: 0;
                background: none;
                .alert__icon-container {
                    display: none;
                }
                h3 {
                    display: none;
                }
                label {
                    display: flex;
                    align-items: center;
                }
            }
            &.alert--type-danger {
                background-color: #fde7e7;
                border-radius: 0;
                padding: 5px 10px;
                margin-top: 10px;
                p {
                    margin: 0;
                }
            }
            &__content {
                span {
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            &__icon-container {
                padding-right: 10px;
            }
            .ivu-icon {
                font-size: 22px !important;
                color: #8b919a;
            }
        }
        .importExportModal__upload-controls {
            align-items: flex-start;
        }
        .hox-modal__body {
            padding-top: 0;
        }
        .hox-modal__header {
            margin-bottom: 10px;
        }
    }
    &__subtitle {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
    }
    &__external-generated {
        margin-bottom: 15px;
    }
}
