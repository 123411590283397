
@use "sass:color";
@import "../../../sass/variables";

.add-template-to-campaign {
    background: $campaign-nav-bg;
    color: $cmp-dark-font-color;
    /*position: fixed;*/
    /*z-index: 10;*/
    width: calc(100% - #{$campaign-vertical-nav-width});
    height: calc(100vh - #{$campaign-nav-height} - #{$campaign-main-content-padding-top});
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 600px;
        margin-top: 10vh;
    }

    h2 {
        flex: 0;
        font-weight: normal;
        font-size: 28px;
        text-align: center;
        max-width: 400px;
        margin: 0 auto 50px;
    }

    &__action {
        cursor: pointer;
    }

    &__actions {
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &--browse,
        &--upload {
            background: $cmp-dark-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px;
            width: 280px;
            height: 280px;

            button,
            svg {
                display: block;
            }

            button {
                background: $primary-color;
                color: $white;
                border: none;
                width: 180px;

                &:hover {
                    background: color.adjust($primary-color, $lightness: 10%);
                    color: $white;
                    border: none;
                }
            }

            p {
                text-align: center;
                font-size: 12px;
                opacity: 0.5;
                width: 180px;
                margin-top: 10px;
            }
        }
    }
}
