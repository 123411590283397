
@use "sass:color";
@import "../../../sass/variables";

.campaign-edit__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: -20px 0 0 0;
    height: 60px;

    &-action-wrapper {
        flex: 1;
        display: flex;

        &.right {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            white-space: nowrap;
        }
    }

    &-action-select {
        max-width: 175px;
    }

    &-action-btn,
    &-action-select .ivu-select-selection,
    &-action-select .ivu-select-dropdown {
        background: $cmp-dark-btn-bg-color;
        color: $cmp-dark-font-color;
        border: 1px solid $cmp-dark-btn-bg-color;
        margin-right: 5px;

        > span {
            display: block;
        }

        &[disabled] {
            opacity: 0.5;
            background: $cmp-dark-btn-bg-color;
            color: $cmp-dark-font-color;
            border: 1px solid $cmp-dark-btn-bg-color;

            &:hover {
                background: $cmp-dark-btn-bg-color;
                color: $cmp-dark-font-color;
                border: 1px solid $cmp-dark-btn-bg-color;
            }
        }

        &:hover {
            background: color.adjust($cmp-dark-btn-bg-color, $lightness: 10%);
            border: 1px solid color.adjust($cmp-dark-btn-bg-color, $lightness: 10%);
            color: $cmp-dark-font-color;
        }

        &--primary {
            background: $blue;
            border: 1px solid $blue;

            &[disabled] {
                background: $blue;
                border: 1px solid $blue;

                &:hover {
                    background: $blue;
                    border: 1px solid $blue;
                }
            }

            &:hover {
                background: color.adjust($blue, $lightness: 10%);
                border: 1px solid color.adjust($blue, $lightness: 10%);
            }
        }
    }

    &-action-select.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
        padding-left: 15px;
        padding-right: 32px;
    }

    &-action-select .ivu-select-dropdown {
        margin-top: 1px;
        padding: 0;
        height: auto;

        &:hover {
            background: $cmp-dark-btn-bg-color;
        }

        .ivu-select-item {
            color: $cmp-dark-font-color;
            border-bottom: 1px solid $cmp-dark-border-color;
            padding: 10px;

            &:last-child {
                border-bottom: none;
            }

            &.ivu-select-item-selected {
                color: $cmp-dark-active;
            }

            &.ivu-select-item-focus {
                background: $cmp-dark-btn-bg-color;
            }

            &:hover {
                background: color.adjust($cmp-dark-btn-bg-color, $lightness: 10%);
            }
        }
    }
}
