
@use "sass:color";
@import "../../../sass/_variables.scss";

$size: 40px;
$small-size: 28px;

@mixin make-button-style($color, $darkenPercentage, $lightenPercentage) {
    /*
    Instead of a darken or lighten percentage it would be ideal if we already
    had a scale of each color to work from, for example:

    $red: (
      darkest: hsl(11, 90%, 10%),
      darker: hsl(11, 90%, 20%),
      dark: hsl(11, 90%, 30%),
      base: hsl(11, 90%, 45%),
      light: hsl(11, 90%, 60%),
      lighter: hsl(11, 90%, 75%),
      lightest: hsl(11, 90%, 90%),
    );
  */
    $darkColor: color.adjust($color, $lightness: -$darkenPercentage);
    $lightColor: color.adjust($color, $lightness: $lightenPercentage);

    color: $color;

    &:hover {
        background: $lightColor;
        color: $darkColor;
    }

    &.square-button--solid {
        background: $color;
        color: $white;

        &:hover {
            background: $darkColor;
            color: $white;
        }

        &.square-button--inverse-hover:hover {
            background: $lightColor;
            color: $darkColor;
        }

        .square-button__loading-layer {
            background: rgba($darkColor, 0.95);
        }

        .square-button__loading-icon {
            color: $white;
        }
    }

    &.square-button--active {
        background: $color;
        color: $white;

        &:hover {
            background: $darkColor;
            color: $white;
        }

        .square-button__loading-layer {
            background: rgba($darkColor, 0.95);
        }

        .square-button__loading-icon {
            color: $white;
        }
    }

    &.square-button--inverse-hover:hover {
        background: $darkColor;
        color: $white;
    }

    .square-button__loading-layer {
        background: rgba($lightColor, 0.95);
    }

    .square-button__loading-icon {
        color: $darkColor;
    }
}

@keyframes square-button__spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.square-button {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: $size;
    justify-content: center;
    position: relative;
    transition:
        background 0.2s linear,
        color 0.2s linear;
    width: $size;

    &:focus {
        outline: none;
        z-index: 1;
    }
}

.square-button--small {
    height: $small-size;
    width: $small-size;
}

.square-button--danger {
    @include make-button-style($red, 25%, 15%);
}

.square-button--default {
    @include make-button-style($grey5, 17.5%, 25%);
}

.square-button--primary {
    @include make-button-style($link-color, 20%, 15%);
}

.square-button--success {
    @include make-button-style($green, 15%, 25%);
}

.square-button--disabled {
    opacity: 0.8;
}

.square-button__disabled-layer {
    bottom: 0;
    cursor: not-allowed;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.square-button__loading-icon {
    animation: square-button__spin 1s linear infinite;
}

.square-button__loading-layer {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.square-button__wrapper {
    display: inline-block;
    position: relative;
}
