
@use "sass:color";
@import "@/../sass/_variables.scss";

$user-gap: 1px;
$user-size: 25px;
$user-overlap-size: calc($user-size / 3);

.qa-user-status-indicators {
    display: flex;

    .ivu-poptip {
        display: flex;
    }
}

.qa-user-status-indicators__approved-icon {
    color: $success-color;
}

.qa-user-status-indicators__rejected-icon {
    color: $error-color;
}

.qa-user-status-indicators__user-dot {
    align-items: center;
    border-radius: 100%;
    border: $user-gap solid $grey1;
    color: $white;
    display: flex;
    height: $user-size;
    justify-content: center;
    margin: 0 (0 - $user-overlap-size) 0 0;
    width: $user-size;
}

.qa-user-status-indicators__user-dot--approved {
    background: linear-gradient(45deg, mix($success-color, $link-color, 75%), $success-color);
}

.qa-user-status-indicators__user-dot--rejected {
    background: linear-gradient(45deg, color.adjust($error-color, $lightness: -22.5%), $error-color);
}

.qa-user-status-indicators__user-dot-inner-group {
    display: flex;
    flex-direction: row-reverse;
}

.qa-user-status-indicators__user-dot-group {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 $user-overlap-size 0 0;
}

.qa-user-status-indicators__user-list-item {
    font-weight: normal;
}

.qa-user-status-indicators__user-list-title {
    font-weight: bold;
}
