
@use "sass:color";
@import "@/../sass/_variables.scss";

$icon-container-size: 34px;
$icon-size: 24px;

.main-nav-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $input-height-large;
    justify-content: center;
    position: relative;
    transition: color $transition-time-base $transition-function-base;
    width: $input-height-large;

    &--theme-light {
        color: $cmp-light-font-color;

        .main-nav-button__icon-container--circle-border {
            border-color: $cmp-light-font-color;
        }
    }

    &--theme-dark {
        color: $cmp-dark-font-color;

        .main-nav-button__icon-container--circle-border {
            border-color: $cmp-dark-font-color;
        }
    }

    &--active,
    &:hover {
        color: $white;

        .main-nav-button__background {
            opacity: 1;
        }

        .main-nav-button__icon-container--circle-border {
            border-color: $white;
        }
    }

    &:hover {
        .main-nav-button__background {
            background-image: linear-gradient(
                90deg,
                color.adjust(#1300ee, $lightness: 5%) 0%,
                color.adjust(#3dc1ff, $lightness: 5%) 100%
            );
        }
    }
}

.main-nav-button__background {
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(90deg, #1300ee 0%, #3dc1ff 100%);
    transition: opacity $transition-time-base $transition-function-base;
}

.main-nav-button__badge-container {
    position: absolute;
    top: $spacing-smallest;
    right: $spacing-smallest;

    .ivu-badge-count {
        background: $error-color;
        border-radius: 100%;
        box-shadow: none;
        font-family: inherit;
        font-size: $font-size-smaller;
        height: 20px;
        padding: 0;
        width: 20px;
    }
}

.main-nav-button__icon-container {
    align-items: center;
    display: flex;
    font-size: $icon-size;
    height: $icon-container-size;
    justify-content: center;
    position: relative;
    width: $icon-container-size;

    .ivu-icon {
        font-size: $icon-size;
    }

    &--circle-border {
        border-radius: $icon-container-size;
        border-style: solid;
        border-width: 2px;
        font-size: 18px;
        transition: border-color $transition-time-base $transition-function-base;

        .ivu-icon {
            font-size: 20px;
        }
    }

    > svg {
        display: inline-block;
        fill: currentColor;
        height: 1em;
    }
}

.main-nav-button__wrapper {
    &--margin-bottom-base {
        margin-bottom: $spacing-base;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--margin-bottom-none {
        margin-bottom: 0;
    }
}
